import PropTypes from "prop-types";

export const expertType = PropTypes.shape({
  photo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  checks: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
});
