import cn from "classnames";

import Link from "next/link";
import { useRouter } from "next/router";

import REVIEWS, { ReviewProps } from "@/config/data/reviews";
import { EVENT_VALUES, EVENT_TYPES } from "@/config/data/tracking";
import * as tracking from "@/lib/tracking";
import { useDarkMode } from "@/lib/hooks/darkMode";

import Image from "@/components/Image";
import { H, Highlight, Uppercase } from "@/components/text";

import styles from "./index.module.scss";
import ReviewsGrid from "../ReviewsGrid";

export interface ReviewsProps {
  reviewsList?: ReviewProps[];
  className?: string;
}

const Reviews = ({ reviewsList = REVIEWS, className = "" }: ReviewsProps) => {
  const router = useRouter();

  const { isDarkMode } = useDarkMode();

  const trackTrustpilotAction = () => {
    if (router.pathname === "/mastered") {
      tracking.event(EVENT_TYPES.interactedWithCampaign, {
        interaction: EVENT_VALUES.campaignInteraction.trustpilot,
      });
    }
  };

  return (
    <section className={cn("text-center", className)}>
      <Uppercase tag="p" className="py-md-3 m-md-0">
        15,000+ reviews and counting
      </Uppercase>
      <div className="container">
        <div className="row">
          <header className="col-md-7 m-auto">
            <H tag="h2" size="h1">
              Members <Highlight variant="circle">love</Highlight>{" "}
              <span className="text-nowrap">Save My Exams</span>
            </H>
            <Link
              className={styles.badge}
              href="https://www.trustpilot.com/review/www.savemyexams.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              title="Read more reviews"
              onClick={trackTrustpilotAction}
            >
              <Image
                className={styles.stars}
                src={`/illustrations/temp/trustpilot${
                  isDarkMode ? "-dark" : ""
                }.svg`}
                width={251}
                height={85}
                alt="Our rating represented in Trustpilot stars"
              />
            </Link>
          </header>
        </div>
        <div className="row">
          <div className={styles.container}>
            <ReviewsGrid
              items={reviewsList}
              onTrustpilotClick={trackTrustpilotAction}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
