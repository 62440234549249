const EXPERTS = [
  {
    photo: {
      url: "/images/experts/expert-jenna-quinn.webp",
      alt: "Head of Humanities & Social Sciences Jenna Quinn",
    },
    name: "Jenna Quinn",
    subject: "Content",
    checks: [
      "Head of Humanities & Social Sciences",
      "10+ years teaching experience",
    ],
    path: "/authors/jenna-quinn/",
    role: "Head of Humanities & Social Sciences",
  },
  {
    photo: {
      url: "/images/experts/02-expert-maths-lucy-kirkham.webp",
      alt: "Head of STEM Lucy Kirkham",
    },
    name: "Lucy Kirkham",
    subject: "Maths",
    checks: ["Head of STEM", "12+ years teaching experience"],
    path: "/authors/lucy-kirkham/",
    role: "Head of STEM",
  },
];

export const EXPERTS_BY_PAGE = {
  IB: [
    {
      photo: {
        url: "/images/experts/expert-stewart-hird.webp",
        alt: "IB Chemistry Subject Lead Stewart Hird",
      },
      name: "Stewart Hird",
      subject: "Chemistry",
      checks: [],
      path: "/authors/stewart-hird/",
      role: "IB Chemistry Subject Lead",
    },
    {
      photo: {
        url: "/images/experts/expert-cara-head.webp",
        alt: "IB Biology Content Creator Cara Head",
      },
      name: "Cara Head",
      subject: "Biology",
      checks: [],
      path: "/authors/cara-head/",
      role: "IB Biology Content Creator",
    },
    {
      photo: {
        url: "/images/experts/expert-naomi-calverd.webp",
        alt: "IB Maths Content Creator Naomi Calverd",
      },
      name: "Naomi Calverd",
      subject: "Maths",
      checks: [],
      path: "/authors/naomi-calverd/",
      role: "IB Maths Content Creator",
    },
  ],
};

export default EXPERTS;
