import cn from "classnames";
import PropTypes from "prop-types";
import Link from "next/link";

import ImageFill from "../ImageFill";
import CheckCircleIcon from "@material-design-icons/svg/filled/check_circle.svg";
import { H } from "src/components/text";
import Icon from "src/components/Icon";
import { expertType } from "src/config/types";
import getConfig from "next/config";

import styles from "./index.module.scss";

const {
  publicRuntimeConfig: { baseUrl },
} = getConfig();

const ExpertCard = ({
  expert,
  link = false,
  tag = "article",
  checklist = false,
  ...rest
}) => {
  const Element = tag;
  const Wrapper = link ? Link : "div";
  return (
    <Element className="col-lg-4 col-md-6" {...rest}>
      <Wrapper
        href={link ? `${baseUrl}${expert.path}` : undefined}
        className={cn({ [styles.link]: link })}
      >
        <figure className={styles.card}>
          <picture className={styles.picture}>
            <ImageFill
              className={styles.image}
              src={expert.photo.url}
              alt={expert.photo.alt}
              sizes="(max-width: 375px) 287px, (max-width: 575px) 487px, (max-width: 767px) 297px, (max-width: 991px) 415px, (max-width: 1199px) 248px, (max-width: 1399px) 308px, 368px"
            />
          </picture>
          <figcaption className={styles.caption}>
            <H tag="h3" size="h4" className={styles.name}>
              {expert.name}
            </H>
            {checklist ? (
              <ul className="list-unstyled text-start m-0">
                {expert.checks.map((check, index) => (
                  <li key={index} className={styles.checklist}>
                    <Icon svg={CheckCircleIcon} className="text-primary me-2" />{" "}
                    {check}
                  </li>
                ))}
              </ul>
            ) : (
              <H tag="h4" size="h6" className={styles.role}>
                {expert.role}
              </H>
            )}
          </figcaption>
        </figure>
      </Wrapper>
    </Element>
  );
};

export default ExpertCard;

ExpertCard.propTypes = {
  className: PropTypes.string,
  expert: expertType,
  link: PropTypes.bool,
  tag: PropTypes.string,
  checklist: PropTypes.bool,
};
