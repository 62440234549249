export interface ReviewProps {
  authorName: string;
  authorContext: string;
  authorImage: string;
  quote: string;
  variant: string;
}

const REVIEWS: ReviewProps[] = [
  {
    authorName: "Beth",
    authorContext: "IGCSE Student",
    authorImage: "/images/join/profile-beth.png",
    quote:
      "I would just like to say a massive thank you for putting together such a brilliant, easy to use website. I really think using this site helped me secure my top grades in science and maths. You really did save my exams! Thank you.",
    variant: "book",
  },
  {
    authorName: "Fathima",
    authorContext: "A Level Student",
    authorImage: "/images/join/profile-fathima.png",
    quote:
      "This website is soooo useful and I can’t ever thank you enough for organising questions by topic like this. Furthermore, the name of the website could not have been more appropriate as it literally did SAVE MY EXAMS!",
    variant: "pencil",
  },
  {
    authorName: "Mark",
    authorContext: "Chemistry Teacher",
    authorImage: "/images/join/profile-mark.png",
    quote:
      "Just to say that your resources are the best I have seen and I have been teaching chemistry at different levels for about 40 years",
    variant: "plane",
  },
  {
    authorName: "Kate",
    authorContext: "GCSE Student",
    authorImage: null,
    quote:
      "Incredible! SO worth my money, the revision notes have everything I need to know and are so easy to understand. I actually enjoy revising! It makes me feel a lot more confident for my GCSEs in a few months.",
    variant: "alarm",
  },
  {
    authorName: "Sameera",
    authorContext: "Parent",
    authorImage: null,
    quote:
      "Absolutely brilliant, both my girls used it for A levels and GCSE. It’s saves on paper copies, also beneficial topic questions ranked from easy to hard. It’s removed a lot of stress from the exams.",
    variant: "bulb",
  },
];

export const REVIEWS_BY_PAGE = {
  TEACHERS: [
    {
      authorName: "Gary",
      authorContext: "Teacher",
      authorImage: null,
      quote:
        "As a full time teacher, i need resources that fit what I do. I do not need worksheets full of explanations, that is my job. What i need are great questions that reinforce what i teach and then extend or support my pupils. This is EXACTLY what 'Save My Exams' does. Thank you SME. You are one of the best weapons i have in the classroom.",
      variant: "book",
    },
    {
      authorName: "Richard Aviomoh",
      authorContext: "Teacher",
      authorImage: null,
      quote:
        "The content provided is very useful. As a tutor, it provides me with relevant materials to use to teach students. No complaints!",
      variant: "pencil",
    },
    {
      authorName: "Mark",
      authorContext: "Chemistry Teacher",
      authorImage: "/images/join/profile-mark.png",
      quote:
        "Just to say that your resources are the best I have seen and I have been teaching chemistry at different levels for about 40 years",
      variant: "plane",
    },
    {
      authorName: null,
      authorContext: "A Level Student",
      authorImage: null,
      quote:
        "Concise information that caters to every students’ needs without coming off as a burden of information that is to be learned arduously. Everything is organized and neat, making studying a smooth sailing experience. Trustworthy resources and reliable information made easier for difficult exams! What more could a student ask for?",
      variant: "alarm",
    },
    {
      authorName: "Zaarah",
      authorContext: "GCSE Student",
      authorImage: null,
      quote:
        "Save my exams have perfect revision notes that are actively engaging for each topic. Each topic has a 'test yourself section' which is very useful for consolidating knowledge. Starting from easy to hard, There are even modelled answers and references to where the notes are in regard to the question so you understand where your weaknesses are. What more could you want! Your straight path to GCSE success!",
      variant: "bulb",
    },
  ],
};

export default REVIEWS;
