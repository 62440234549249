import cn from "classnames";

import Link from "next/link";

import { useEffect, useRef } from "react";

import { ReviewProps } from "@/config/data/reviews";
import { useDarkMode } from "@/lib/hooks/darkMode";

import Image from "@/components/Image";
import Button from "@/components/Button";
import Review from "@/components/Review/Campaign";
import { Uppercase } from "@/components/text";

import styles from "./ReviewsGrid.module.scss";

export interface ReviewsGridProps {
  items: ReviewProps[];
  onTrustpilotClick: () => void;
  className?: string;
}

const ReviewsGrid = ({
  items,
  onTrustpilotClick,
  className,
}: ReviewsGridProps) => {
  const reviews = useRef(null);

  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Masonry = require("masonry-layout");
    setTimeout(() => {
      new Masonry(reviews.current, {
        itemSelector: '[data-content="review"]',
        horizontalOrder: true,
        gutter: 24,
      });
    });
  }, [reviews]);

  return (
    <ul
      className={cn(styles.reviews, "row gy-4 list-unstyled", className)}
      ref={reviews}
    >
      {items.map((review, index) => (
        <Review
          variant={review.variant}
          quote={review.quote}
          author={review.authorName}
          role={review.authorContext}
          key={`review-${index}`}
        />
      ))}
      <li className={styles.trustpilot} data-content="review">
        <div>
          <span className="d-block m-0 h4">Rated Excellent</span>
          <Uppercase size="S">on Trustpilot</Uppercase>
        </div>
        <Image
          className={styles.stars}
          src={`/illustrations/temp/trustpilot${isDarkMode ? "-dark" : ""}.svg`}
          width={251}
          height={85}
          alt="Our rating represented in Trustpilot stars"
        />
        <Button
          href="https://www.trustpilot.com/review/www.savemyexams.co.uk"
          target="_blank"
          rel="noopener noreferrer"
          tag={Link}
          variant="primary"
          size="L"
          wide
          onClick={onTrustpilotClick}
        >
          Read more reviews
        </Button>
      </li>
    </ul>
  );
};

export default ReviewsGrid;
