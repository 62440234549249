import PropTypes from "prop-types";

export const subjectType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  year: PropTypes.string,
  "count_topic-questions": PropTypes.number.isRequired,
  "count_past-paper-questions": PropTypes.number.isRequired,
  "count_practice-paper-questions": PropTypes.number.isRequired,
  "count_revision-notes": PropTypes.number.isRequired,
  extraResources: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
});
