import PropTypes from "prop-types";

import Image from "@/components/Image";
import { P } from "@/components/text";
import { useDarkMode } from "@/lib/hooks/darkMode";

import styles from "./index.module.scss";

const Review = ({ variant, quote, author, role }) => {
  const { isDarkMode } = useDarkMode();
  return (
    <li className={styles.review} data-content="review">
      <Image
        className={styles.illustration}
        src={`/illustrations/temp/${variant}${isDarkMode ? "-dark" : ""}.png`}
        width="64"
        height="64"
        alt=""
      />
      <figure className={styles.figure}>
        <blockquote className={styles.blockquote}>{quote}</blockquote>
        <figcaption>
          <P strong className={styles.author}>
            {author}
          </P>
          <P className={styles.author}>{role}</P>
        </figcaption>
      </figure>
    </li>
  );
};

export default Review;

Review.propTypes = {
  variant: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};
