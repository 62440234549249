import { useMemo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Image from "@/components/Image";

import styles from "./index.module.scss";

const ImageFill = ({
  alt,
  aspectRatio = "1:1",
  className,
  priority = false,
  shape = "rounded",
  src,
  sizes,
}) => {
  const padding = useMemo(() => {
    const [width, height] = aspectRatio.split(":").map(Number);
    return (height / width) * 100;
  }, [aspectRatio]);

  return (
    <div
      className={cn(styles.imageWrapper, className)}
      style={{ "--padding-block-end": `${padding}%` }}
    >
      <Image
        src={src}
        fill
        alt={alt}
        priority={priority}
        className={cn(styles.image, styles[shape])}
        sizes={sizes}
      />
    </div>
  );
};

export default ImageFill;

ImageFill.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  /**
   * Set the `priority` prop if the image appears above the fold.
   * This ensures that the `loading="lazy"` prop is **not** applied by the `next/image` component.
   */
  priority: PropTypes.bool,
  shape: PropTypes.oneOf(["rounded", "circle"]),
  src: PropTypes.string.isRequired,
  sizes: PropTypes.string.isRequired,
};
