import PropTypes from "prop-types";

export const courseType = PropTypes.shape({
  level: PropTypes.string.isRequired,
  board: PropTypes.string,
  subject: PropTypes.string.isRequired,
  module: PropTypes.string,
  boardPathId: PropTypes.number,
  subjectPathId: PropTypes.number.isRequired,
});
